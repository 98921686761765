@import 'mixins';
@import 'variables';

// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// Animate
@import 'animate.min.css';

// Swiper
@import 'swiper/css';
@import 'swiper/css/pagination';
@import 'swiper-custom';

// Tabs
@import 'tabs-custom';

// Common
@import 'common';
