.swiper-pagination {
  .swiper-pagination-bullet {
    width: 24px;
    height: 2px;
    background-color: #d9d9d9;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      background-color: #e88532;
    }
  }
}
