@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.menu-tabs {
  .react-tabs {
    &__tab {
      padding: 0;
      border: 0;
      white-space: nowrap;
      font-size: 14px;
      color: $grey-g-100;
      font-weight: 600;

      &-list {
        border: 0;
        display: flex;
        column-gap: 24px;
        overflow-x: auto;
        overflow-y: hidden;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        padding-bottom: 24px;
        margin: 0;

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
          display: none;
        }

        @include media-breakpoint-up('xl', $tailwind-breakpoints) {
          justify-content: center;
        }
      }

      &--selected {
        color: $blue-b-300;
        border-bottom: 1px solid $blue-b-300;
      }
    }
  }
}
